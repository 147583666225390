<template>
  <div class="report-list-wrap">
    <div class="button-wrap">
      <el-button type="primary" style="width: 100%" :disabled="getReportList.length === 0" @click="downloadCSV()">
        下載CSV
      </el-button>
    </div>
    <el-table
      ref="listTable"
      :data="getReportList"
      height="100%"
      style="width: 100%"
      header-cell-class-name="cus-table-header"
      row-class-name="cus-table-row"
      empty-text="尚無資料"
    >
      <el-table-column prop="shop_name" label="門市" width="180">
        <template #default="scope">
          <span>{{ scope.row.shop_erp_id }} {{ scope.row.shop_name }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="營業日期" width="180">
        <template #default="scope">
          <span>
            {{ formatDateTime('YYYY/MM/DD', scope.row.date) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="order_count" label="訂單筆數" width="180" />
      <el-table-column prop="order_total_amount" label="銷售總淨額" width="180">
        <template #default="scope">
          <span>
            {{
              scope.row.order_total_amount +
              scope.row.redundant_cash -
              scope.row.order_total_discount -
              scope.row.total_discount
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="invoice_total_sales_amount" label="發票總金額">
        <template #default="scope">
          <span>
            {{
              scope.row.invoice_total_sales_amount +
              scope.row.invoice_total_tax_amount +
              scope.row.invoice_total_free_tax_amount
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" align="right" width="150">
        <template #default="scope">
          <el-button
            type="info"
            plain
            style="font-weight: normal; border: none; width: 110px"
            @click="handleClickDetail(scope.$index, scope.row)"
          >
            詳細
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      v-model:currentPage="currentPage"
      v-model:page-size="getPageSize"
      layout="prev, pager, next, jumper"
      :total="getPageTotal"
      @click="handleCurrentChange"
    />
  </div>
</template>
<script>
import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { getFormatDate, formatDateTime } from '@/utils/datetime';
export default {
  name: 'report-list',
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { getReportList, getCSVList, getPageSize, getPageTotal, getIsDetail, getSearchCondition } = useGetters(
      'report',
      ['getReportList', 'getCSVList', 'getPageSize', 'getPageTotal', 'getIsDetail', 'getSearchCondition']
    );
    const { SET_IS_DETAIL, SET_SEARCH_CONDITION } = useMutations('report', ['SET_IS_DETAIL', 'SET_SEARCH_CONDITION']);
    const { doSearchReportList, doCSVList } = useActions('report', ['doSearchReportList', 'doCSVList']);
    //初始化
    const listTable = ref(null);
    const currentPage = ref(1);
    const query = { page: route.query.page && getSearchCondition.value ? route.query.page : 1 };
    const searchDataInit = {
      shop_id: [],
      date: [getFormatDate().slice(0, 8) + '01', getFormatDate()],
    };
    router.push({ query, replace: true }).then(async () => {
      currentPage.value = Number(route.query.page);
      if (route.path === '/order/report' && !getIsDetail.value) {
        SET_SEARCH_CONDITION(searchDataInit);
        await doSearchReportList({
          search: searchDataInit,
          start: (currentPage.value - 1) * getPageSize.value,
          type: 'search',
        });
      } else {
        SET_IS_DETAIL(false);
      }
    });
    //點擊分頁
    const handleCurrentChange = () => {
      const query = { page: currentPage.value };
      router.push({ query });
    };
    watch(
      () => route.query.page,
      async () => {
        if (route.query.page && getSearchCondition.value) {
          currentPage.value = Number(route.query.page);
          await doSearchReportList({
            search: getSearchCondition.value,
            start: (currentPage.value - 1) * getPageSize.value,
            type: 'page',
          });
          listTable.value.scrollBarRef.scrollTo(0, 0);
        }
      }
    );

    //點擊詳細
    const { SET_CURRENT_INDEX, SET_REPORT_DETAIL } = useMutations('report', ['SET_CURRENT_INDEX', 'SET_REPORT_DETAIL']);
    const doGetClientList = useActions('report', ['doGetClientList']);
    const handleClickDetail = async (index, item) => {
      await doGetClientList({ shop_id: item.shop_id, date: item.date });
      SET_CURRENT_INDEX(index);
      SET_REPORT_DETAIL(item);
      router.push('/order/report/detail');
    };

    //下載CSV
    const downloadCSV = async () => {
      await doCSVList({
        search: getSearchCondition.value,
        start: (currentPage.value - 1) * getPageSize.value,
      });
      let newData = getCSVList.value;
      const buildData = (recordData) => {
        const newData = recordData.map((item) => {
          const salesTotal =
            item.order_total_amount + item.redundant_cash - item.order_total_discount - item.total_discount;
          const invoiceTotal =
            item.invoice_total_sales_amount + item.invoice_total_tax_amount + item.invoice_total_free_tax_amount;
          const needCash = item.current_cash - item.other_expense;
          return {
            ...item,
            need_cash: needCash,
            cash_box: needCash,
            invoice_total: invoiceTotal,
            sales_total: salesTotal,
          };
        });
        // console.log('buildData', newData);
        return new Promise((resolve) => {
          // 最後所有的資料會存在這
          let arrayData = [];

          //中英對照表
          const comparisonTable = ref([
            { label: '門市名稱', value: 'shop_name' },
            { label: '店家編號', value: 'shop_erp_id' },
            { label: '交易序號（起）', value: 'start_serial_number' },
            { label: '發票號碼（起）', value: 'start_invoice_no' },
            { label: '營業日期', value: 'date' },
            { label: '交易序號（迄）', value: 'end_serial_number' },
            { label: '發票號碼（迄）', value: 'end_invoice_no' },
            { label: '訂單筆數', value: 'order_count' },
            { label: '銷售數−產品數', value: 'order_product_count' },
            { label: '自備杯獎勵(現金)筆數', value: 'event_cash_count' },
            { label: '自備杯獎勵(旺卡)筆數', value: 'event_card_count' },
            { label: '銷售數−杯數', value: 'order_item_count' },
            { label: '銷售數−周邊商品', value: 'order_other_product_count' },
            { label: '自備杯獎勵(現金)金額', value: 'event_cash_amount' },
            { label: '自備杯獎勵(旺卡)金額', value: 'event_card_amount' },
            { label: '旺卡消費', value: 'want_card' },
            { label: '黃金點數', value: 'want_card_point' },
            { label: '現金抵用卡', value: 'cash_card' },
            { label: '外送行動支付', value: 'payment_by_outside_payment_app' },
            { label: '現金', value: 'cash' },
            { label: 'Line Pay', value: 'line_pay' },
            { label: '街口支付', value: 'jeko' },
            { label: '台灣Pay', value: 'taiwan_pay' },
            { label: '支付寶', value: 'ali_pay' },
            { label: '悠遊付', value: 'uupay' },
            { label: '信用卡', value: 'credit_card' },
            { label: '一卡通', value: 'i_pass' },
            { label: '悠遊卡', value: 'easy_card' },
            { label: 'FoodPanda', value: 'foodpanda' },
            { label: 'Uber Eats', value: 'uber_eat' },
            { label: 'Foodomo', value: 'foodomo' },
            { label: 'line@訂餐', value: 'line' },
            { label: '禮券', value: 'gift_certificate' },
            { label: '園區員工卡', value: 'park_employee_card' },
            { label: '銷售總金額(+)', value: 'order_total_amount' },
            { label: '折扣金額(−)', value: 'order_total_discount' },
            { label: '折抵金額(−)', value: 'total_discount' },
            { label: '銷售總淨額', value: 'sales_total' },
            { label: '溢收(+)', value: 'redundant_cash' },
            { label: '電子發票-作廢筆數', value: 'void_invoice_count' },
            { label: '電子發票-作廢總金額', value: 'void_invoice_total_amount' },
            { label: '發票數量', value: 'invoice_count' },
            { label: '應稅銷售額(+)', value: 'invoice_total_sales_amount' },
            { label: '免稅銷售額(+)', value: 'invoice_total_free_tax_amount' },
            { label: '發票總金額', value: 'invoice_total' },
            { label: '應稅稅額(+)', value: 'invoice_total_tax_amount' },
            { label: '旺卡加值', value: 'want_card_add' },
            { label: '掛單更正商品數', value: 'order_item_reedit_count' },
            { label: '開櫃次數', value: 'open_cash_box_count' },
            { label: '旺卡退值', value: 'want_card_refund' },
            { label: '掛單更正總金額', value: 'order_item_reedit_amount' },
            { label: '應收現金(+)', value: 'current_cash' },
            { label: '雜支(−)', value: 'other_expense' },
            { label: '抽屜應有現金', value: 'need_cash' },
            { label: '實際抽屜現金', value: 'cash_box' },
            { label: '機台號碼', value: 'client_id' },
          ]);

          // 取 data 的第一個 Object 的 key 當表頭
          let arrayTitle = Object.keys(newData[0]);

          // 中英轉換
          // idArray: array -> csv 的 key
          const idArray = arrayTitle.map((item) => {
            const matchedItem = comparisonTable.value.find((tableItem) => tableItem.value === item);
            return matchedItem ? matchedItem.label : item;
          });
          arrayData.push(idArray);

          // 取出每一個 Object 裡的 value，push 進新的 Array 裡
          Array.prototype.forEach.call(newData, (d) => {
            // items: array -> csv 的 value
            let items = [];
            Array.prototype.forEach.call(arrayTitle, (title) => {
              let item = d[title] || '0';
              items.push(item);
            });
            arrayData.push(items);
          });
          resolve(arrayData);
        });
      };

      const csv = (data) => {
        let csvContent = '';
        Array.prototype.forEach.call(data, (d) => {
          let dataString = d.join(',') + '\n';
          csvContent += dataString;
        });

        // 下載的檔案名稱
        let fileName = new Date().getTime() + '_銷售報表.csv';

        // 建立一個 a，並點擊它
        let link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvContent));
        link.setAttribute('download', fileName);
        link.click();
      };

      buildData(newData).then((val) => csv(val));
    };

    return {
      formatDateTime,
      getReportList,
      getCSVList,
      listTable,
      currentPage,
      getPageSize,
      getPageTotal,
      handleCurrentChange,
      handleClickDetail,
      downloadCSV,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
$pagination-height: 50px;
.report-list-wrap {
  width: 100%;
  height: 100%;
  padding: $padding-unit $padding-unit $pagination-height !important;
  position: relative;
  overflow: scroll;
  @include white-bg;
}
.button-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 110px;
}
.pagination {
  position: absolute;
  left: 50%;
  bottom: calc($pagination-height / 2);
  transform: translate(-50%, 50%);
}
</style>
